<template>
<div class="main-contents code">
    <div class="tit">직무 등록/수정</div>
        <div class="search-box">
            <SelectComp class="wd01" v-model="ncsDetail.useYn" list="Y:사용,N:사용안함" :isAll="true" title="사용여부" /> 
            <img class="search_btn" @click="getNcsList()" src="/images/admin/search_btn.png" alt="검색버튼"/>
        </div>
    <!-- 중분류 -->
    <div class="Board type3 cf">
        <div class="cf_title">1. 중분류명을 선택하세요</div>
        <div class="catg">[정보통신]</div>
        <table class="Board_type3 admin">
            <colgroup>
                <col width="6%">
                <col width="17%">
                <col width="15%">
                <col width="17%">
                <col width="15%">
                <col width="8%">
                <col width="8%">
                <col width="7%">
                <col width="4%">
            </colgroup> 
            <thead>
                <tr>
                    <th>대분류코드</th>
                    <th>[대분류명]</th>
                    <th>중분류코드</th>
                    <th>1.중분류명</th>
                    <th>사용여부</th>
                    <th>소분류 건수</th>
                    <th>세분류 건수</th>
                    <th></th>
                    <th></th>
                </tr>
            </thead>
            <tbody  v-if="ncsMidList.length > 0">
                <tr v-for="(mid, midindex) in ncsMidList" :key="midindex">
                    <td class="score">{{mid.majorClassCd}}</td>
                    <td class="score">{{mid.majorClassNm}}</td>

                    <td class="score" v-if="midindex == testclick">
                        <InputComp :disabled="true" :value="mid.midClassCd" maxByte="4"/>
                    </td>
                    <td class="score" v-else>{{mid.midClassCd}}</td>

                    <td class="score name" v-if="midindex == testclick">
                        <InputComp v-model="midDetail.midClassNm" :value="mid.midClassNm" maxByte="100"/>
                    </td>
                    <td class="score name" @click="showMinor(mid.midClassNm, mid.midClassCd)" v-else>{{mid.midClassNm}} &#9654;</td>

                    <td class="score" v-if="midindex == testclick">
                        <SelectComp v-model="midDetail.useYn" :value="mid.useYn" list="Y:사용,N:사용안함" maxByte="1" />
                    </td>

                    <td class="score" v-else>
                        <SelectComp type="text" :value="mid.useYn" list="Y:사용,N:사용안함" />
                    </td>

                    <td class="score">{{mid.minycnt}}/{{mid.mincnt}}</td>
                    <td class="score">{{mid.detycnt}}/{{mid.detcnt}}</td>
                    <td class="score">
                        <img v-if="midindex == testclick" src="/images/admin/cf_save_btn.png" @click="updateMidList()" alt="저장버튼"/>
                        <img v-else src="/images/admin/cf_modify_btn.png" alt="수정버튼" @click="changeBtn(mid, midindex)" />
                    </td>
                    <td class="score">
                        <img v-if="midindex == testclick" src="/images/admin/cf_delete_btn.png" @click="changeBtn(mid, midindex)" alt="취소버튼"/>
                    </td>
                </tr>
        
                <tr v-if="addColum != ''">
                    <td class="score">20</td>
                    <td class="score">정보통신</td>
                    <td class="score">
                        <InputComp v-model="insertMid.midClassCd" maxByte="4"/>
                    </td>
                    <td class="score">
                        <InputComp v-model="insertMid.midClassNm" maxByte="100"/>
                    </td>
                    <td class="score">
                        <SelectComp class="wd01" list="Y:사용,N:사용안함" v-model="insertMid.useYn" maxByte="1" />
                    </td>
                    <td class="score"></td>
                    <td class="score"></td>
                    <td class="score"><img src="/images/admin/cf_save_btn.png" @click="insertMidList()" alt="저장버튼"/></td>
                    <td class="score"><img src="/images/admin/cf_delete_btn.png" @click="canCol()" alt="취소버튼"/></td>
                </tr>

            </tbody>
            <tbody v-else>
                <!-- 관련내용 없을 경우 -->
                <tr class="none">
                    <td colspan="9" class="none">등록된 직무가 없습니다!</td>
                </tr>
            </tbody>
        </table>
        <div class="plus_btn" @click="addCol()"><img src="/images/admin/plus_btn.png" alt="추가버튼"/></div>
    </div>

    <!-- 소분류 -->
    <div class="Board type3 cf" v-if="midName != ''">
        <div class="cf_title">2. 소분류명을 선택하세요</div>
        <div class="catg" ref="ncsMinor">[정보통신] 1. {{this.midName}}</div>
        <table class="Board_type3 admin">
            <colgroup>
                <col width="6%">
                <col width="17%">
                <col width="15%">
                <col width="17%">
                <col width="15%">
                <col width="8%">
                <col width="8%">
                <col width="7%">
                <col width="4%">
            </colgroup> 
            <thead>
                <tr>
                    <th></th>
                    <th></th>
                    <th>소분류코드</th>
                    <th>2.소분류명</th>
                    <th>사용여부</th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                </tr>
            </thead>
            <tbody v-if="ncsMinorList.length > 0">
                <tr v-for="(minor, minorindex) in ncsMinorList" :key="minorindex">
                    <td class="score"></td>
                    <td class="score"></td>

                    <td class="score" v-if="minorclick == minorindex">
                        <InputComp type="text" :disabled="true" :value="minor.minorClassCd" />
                    </td>

                    <td class="score" v-else>{{minor.minorClassCd}}</td>

                    <td class="score name" v-if="minorclick == minorindex">
                        <InputComp v-model="minorDetail.minorClassNm" :value="minor.minorClassNm" maxByte="100" />
                    </td>
                    
                    <td class="score name" @click="showDetail(minor.minorClassNm, minor.minorClassCd)" v-else>{{minor.minorClassNm}} &#9654;</td>


                    <td class="score" v-if="minorclick == minorindex">
                        <SelectComp v-model="minorDetail.useYn" :value="minor.useYn" list="Y:사용,N:사용안함" maxByte="1" />
                    </td>
                    
                    <td class="score" v-else>
                        <SelectComp type="text" :value="minor.useYn" list="Y:사용,N:사용안함"/>
                    </td>

                    <td class="score"></td>
                    <td class="score"></td>                
                    <td class="score">
                        <img v-if="minorclick == minorindex" src="/images/admin/cf_save_btn.png" @click="updateMinorList()" alt="저장버튼"/>
                        <img v-else src="/images/admin/cf_modify_btn.png" alt="수정버튼" @click="changeMinor(minor, minorindex)" />
                    </td>
                    <td class="score">
                        <img v-if="minorclick == minorindex" src="/images/admin/cf_delete_btn.png" @click="changeMinor(minor, minorindex)" alt="취소버튼"/>
                    </td>
                </tr>
                
                <tr v-if="addMinorColum != ''">
                    <td class="score"></td>
                    <td class="score"></td>
                    <td class="score">
                        <InputComp v-model="insertMinor.minorClassCd" maxByte="6" />
                    </td>
                    <td class="score">
                        <InputComp v-model="insertMinor.minorClassNm" maxByte="100" />
                    </td>
                    <td class="score">
                        <SelectComp class="wd01" list="Y:사용,N:사용안함" v-model="insertMinor.useYn" maxByte="1" />
                    </td>
                    <td class="score"></td>
                    <td class="score"></td>
                    <td class="score"><img src="/images/admin/cf_save_btn.png" @click="insertMinorList()" alt="저장버튼"/></td>
                    <td class="score" @click="canMinorCol()"><img src="/images/admin/cf_delete_btn.png" alt="취소버튼"/></td>
                </tr>
            </tbody>
            <tbody v-else>
                <!-- 관련내용 없을 경우 -->
                <tr v-if="addMinorColum != ''">
                    <td class="score"></td>
                    <td class="score"></td>
                    <td class="score">
                        <InputComp v-model="insertMinor.minorClassCd" maxByte="6" />
                    </td>
                    <td class="score">
                        <InputComp v-model="insertMinor.minorClassNm" maxByte="100" />
                    </td>
                    <td class="score">
                        <SelectComp class="wd01" list="Y:사용,N:사용안함" v-model="insertMinor.useYn" maxByte="1" />
                    </td>
                    <td class="score"></td>
                    <td class="score"></td>
                    <td class="score"><img src="/images/admin/cf_save_btn.png" @click="insertMinorList()" alt="저장버튼"/></td>
                    <td class="score" @click="canMinorCol()"><img src="/images/admin/cf_delete_btn.png" alt="취소버튼"/></td>
                </tr>
                <tr class="none">
                    <td colspan="9" class="none">등록된 직무가 없습니다!</td>
                </tr>
            </tbody>
        </table>
        <div @click="addMinorCol()" class="plus_btn"><img src="/images/admin/plus_btn.png" alt="추가버튼"/></div>
    </div>

    <!-- 세분류 -->
    <div class="Board type3 cf" v-if="minorName != ''">
        <div class="cf_title">3. 세분류명을 선택하세요</div>
        <div class="catg" ref="ncsDetail">[정보통신] 1. {{this.midName}} > 2. {{this.minorName}}</div>
        <table class="Board_type3 admin">
            <colgroup>
                <col width="6%">
                <col width="17%">
                <col width="15%">
                <col width="17%">
                <col width="15%">
                <col width="16%">
                <col width="7%">
                <col width="4%">
            </colgroup> 
            <thead>
                <tr>
                    <th></th>
                    <th></th>
                    <th>세분류코드</th>
                    <th>3.세분류명</th>
                    <th>사용여부</th>
                    <th>직무기술서 다운로드</th>
                    <th></th>
                    <th></th>
                </tr>
            </thead>
            <tbody v-if="ncsDetailList.length > 0">
                <tr v-for="(detail, detailindex) in ncsDetailList" :key="detailindex">
                    <td class="score"></td>
                    <td class="score"></td>
                    
                    <td class="score" v-if="detailclick == detailindex">
                        <InputComp type="text" :disabled="true" :value="detail.detailClassCd" />
                    </td>
                    <td class="score" v-else>{{detail.detailClassCd}}</td>

                    <td class="score name" v-if="detailclick == detailindex">
                        <InputComp v-model="detaDetail.detailClassNm" :value="detail.detailClassNm" maxByte="100" />
                    </td>
                    <td class="score name" @click="viewAbilDetail(detail.detailClassCd, detail.detailClassNm)" v-else>{{detail.detailClassNm}} &#9654;</td>

                    <td class="score" v-if="detailclick == detailindex">
                        <SelectComp v-model="detaDetail.useYn" :value="detail.useYn" list="Y:사용,N:사용안함" maxByte="1" />
                    </td>
                    <td class="score" v-else>
                        <SelectComp type="text" :value="detail.useYn" list="Y:사용,N:사용안함"/>
                    </td>
                    
                    <td class="score" v-if="detailclick == detailindex">
                        <SelectComp v-model="detaDetail.dutySpecYn" :value="detail.dutySpecYn" list="Y:가능,N:불가능" maxByte="1" />
                    </td>
                    <td class="score" v-else>
                        <SelectComp type="text" :value="detail.dutySpecYn" list="Y:가능,N:불가능"/>
                    </td>
                    
                    <td class="score">
                        <img v-if="detailclick == detailindex" src="/images/admin/cf_save_btn.png" @click="updateDetailList()" alt="저장버튼"/>
                        <img v-else src="/images/admin/cf_modify_btn.png" alt="수정버튼" @click="changeDetail(detail, detailindex)" />
                    </td>
                    <td class="score">
                        <img v-if="detailclick == detailindex" src="/images/admin/cf_delete_btn.png" @click="changeDetail(detail, detailindex)" alt="취소버튼"/>
                    </td>
                </tr>
                <tr v-if="addDetailColum != ''">
                    <td class="score"></td>
                    <td class="score"></td>
                    <td class="score">
                        <InputComp v-model="insertDetail.detailClassCd" maxByte="8" />
                    </td>
                    <td class="score">
                        <InputComp v-model="insertDetail.detailClassNm" maxByte="100" />
                    </td>
                    <td class="score">
                        <SelectComp class="wd01" list="Y:사용,N:사용안함" v-model="insertDetail.useYn" maxByte="1" />
                    </td>
                    <td class="score">
                        <SelectComp class="wd01" list="Y:가능,N:불가능" v-model="insertDetail.dutySpecYn" maxByte="1" />
                    </td>
                    <td class="score"><img src="/images/admin/cf_save_btn.png" alt="저장버튼" @click="insertDetailList()"/></td>
                    <td @click="canDetailCol()" class="score"><img src="/images/admin/cf_delete_btn.png" alt="취소버튼"/></td>
                </tr>
            </tbody>
            <tbody v-else>
                <!-- 관련내용 없을 경우 -->
                <tr v-if="addDetailColum != ''">
                    <td class="score"></td>
                    <td class="score"></td>
                    <td class="score">
                        <InputComp v-model="insertDetail.detailClassCd" maxByte="8" />
                    </td>
                    <td class="score">
                        <InputComp v-model="insertDetail.detailClassNm" maxByte="100" />
                    </td>
                    <td class="score">
                        <SelectComp class="wd01" list="Y:사용,N:사용안함" v-model="insertDetail.useYn" maxByte="1" />
                    </td>
                    <td class="score">
                        <SelectComp class="wd01" list="Y:가능,N:불가능" v-model="insertDetail.dutySpecYn" maxByte="1" />
                    </td>
                    <td class="score"><img src="/images/admin/cf_save_btn.png" @click="insertDetailList()" alt="저장버튼"/></td>
                    <td @click="canDetailCol()" class="score"><img src="/images/admin/cf_delete_btn.png" alt="취소버튼"/></td>
                </tr>
                <tr class="none">
                    <td colspan="8" class="none">등록된 직무가 없습니다!</td>
                </tr>
            </tbody>
        </table>
        <div @click="addDetailCol()" class="plus_btn"><img src="/images/admin/plus_btn.png" alt="추가버튼"/></div>
    </div>
</div>
    
</template>

<script>


export default {
    data() {
        return {
            // 분류 조건 대,중,소
            ncsDetail: {
                ncsHead: "20",
                ncsMajor: "20",
                ncsMid: "",
                ncsMinor: "",
                ncsDeta: "",
                useYn: "",
            },

            // 중분류
            midDetail : {
                midClassCd : '',
                midClassNm : '',
                majorClassCd : '',
                useYn : ''
            },
            insertMid : {
                midClassCd : '',
                midClassNm : '',
                majorClassCd : '20',
                useYn : ''
            },
            ncsMidList: [],
            testclick: '-1',
            addColum: '',

            // 소분류
            minorDetail: {
                minorClassCd : '',
                minorClassNm : '',
                midClassCd : '',
                useYn : '',
                srch : '',
            },
            insertMinor : {
                minorClassCd : '',
                minorClassNm : '',
                midClassCd : '',
                useYn : '',
            },

            ncsMinorList: [],

            midName: '',
            minorclick: '-1',
            addMinorColum: '',

            // 세분류
            detaDetail : {
                detailClassCd : '',
                detailClassNm : '',
                minorClassCd : '',
                dutySpecYn : '',
                useYn : '',
                srch : '',
            },
            insertDetail : {
                detailClassCd : '',
                detailClassNm : '',
                minorClassCd : '',
                dutySpecYn : '',
                useYn : ''
            },

            ncsDetailList : [],

            minorName : '',
            detailclick : '-1',
            addDetailColum : '',

            detailName : ''

        };
    },
    mounted() {
        this.getNcsList();
    },
    methods: {
        getNcsList() {
            var param = this.ncsDetail;

            if(this.midName != '') {
                this.midName = '';
                this.minorclick = '-1';
                this.addMinorColum = '';
                this.minorName = '';
                this.detailclick = '-1';
                this.addDetailColum = '';
            }

            if(this.ncsDetail.useYn == '') {
                this.minorDetail.srch = '';
                this.detaDetail.srch = '';
            }

            this.$.httpPost("/api/main/adm/ncs/getNcsMidList", param)
                .then(res => {
                // console.log("getNcsMidList RESULT", res);
                this.ncsMidList = res.data.ncsMidList;
            }).catch(this.$httpErrorCommon);
        },

        changeBtn(mid, index) {
            if(this.testclick == '-1') {
                this.testclick = index;
                this.midDetail.midClassCd = mid.midClassCd
                this.midDetail.midClassNm = mid.midClassNm
                this.midDetail.majorClassCd = mid.majorClassCd
                this.midDetail.useYn = mid.useYn
            } else if (this.testclick != '-1' && this.testclick != index) {
                this.testclick = index;
                this.midDetail.midClassCd = mid.midClassCd
                this.midDetail.midClassNm = mid.midClassNm
                this.midDetail.majorClassCd = mid.majorClassCd
                this.midDetail.useYn = mid.useYn
            } else {
                this.testclick = '-1'
            } 
        },

        updateMidList() {
            // console.log('fewna',this.midDetail);

            this.$.httpPost('/api/main/adm/ncs/updateNcsMidList', this.midDetail)
                .then(() => {
                    this.getNcsList();
                }).catch(this.$.httpErrorCommon);

            this.testclick = '-1';
        },

        insertMidList(){
            // console.log(this.insertMid);

            if(this.insertMid.midClassCd == '' || this.insertMid.midClassNm == '' || this.insertMid.useYn == '') {
                alert("모든 항목을 입력하여야 합니다.");
            } else {
                this.$.httpPost('/api/main/adm/ncs/insertNcsMidList', this.insertMid)
                .then(() => {
                    this.getNcsList();
                }).catch(this.$.httpErrorCommon);
                this.canCol();
            }
        },

        addCol() {
            this.addColum = '1';
        },

        canCol() {
            this.addColum = '';
            this.insertMid.midClassCd = '';
            this.insertMid.midClassNm = '';
            this.insertMid.useYn = '';
        },

        showMinor(midName, midClassCd) {
            this.midName = midName;
            this.ncsDetail.ncsMid = midClassCd;
            this.minorDetail.midClassCd = midClassCd;
            this.insertMinor.midClassCd = midClassCd;

            if(this.minorName != '') {
                this.minorName = '';
                this.addDetailColum = '';
                this.detailclick = '-1';
                this.canMinorCol();
                this.minorclick = '-1';
            }

            var param = this.minorDetail;
            if(this.ncsDetail.useYn != '') {
                this.minorDetail.srch = this.ncsDetail.useYn;
            }
            this.$.httpPost("/api/main/adm/ncs/getNcsMinorList", param)
                .then(res => {
                // console.log("getNcsMinorList RESULT", res);
                this.ncsMinorList = res.data.ncsMinorList;

                this.$nextTick(() => {
                    this.$.focus(this.$refs.ncsMinor);
                });

            }).catch(this.$httpErrorCommon);
        },

        addMinorCol() {
            this.addMinorColum = '1';
        },

        canMinorCol() {
            this.addMinorColum = '';
            this.insertMinor.minorClassCd = '';
            this.insertMinor.minorClassNm = '';
            this.insertMinor.useYn = '';
        },

        changeMinor(minor, minorindex) {
            if(this.minorclick == '-1') {
                this.minorclick = minorindex;
                this.minorDetail.minorClassCd = minor.minorClassCd;
                this.minorDetail.minorClassNm = minor.minorClassNm;
                this.minorDetail.useYn = minor.useYn;
                
            } else if (this.minorclick != '-1' && this.minorclick != minorindex) {
                this.minorclick = minorindex;
                this.minorDetail.minorClassCd = minor.minorClassCd;
                this.minorDetail.minorClassNm = minor.minorClassNm;
                this.minorDetail.useYn = minor.useYn;
            } else {
                this.minorclick = '-1';
            }

        },

        updateMinorList() {
            // console.log('fewna',this.minorDetail);
            
            this.$.httpPost('/api/main/adm/ncs/updateNcsMinorList', this.minorDetail)
                .then(() => {
                    // console.log('safdfsd',this.minorDetail);
                    this.showMinor(this.minorDetail.minorClassNm, this.minorDetail.midClassCd);
                }).catch(this.$.httpErrorCommon);

            this.minorclick = '-1';
        },

        insertMinorList() {
            // console.log(this.insertMinor);

            if(this.insertMinor.minorClassCd == '' || this.insertMinor.minorClassNm == '' || this.insertMinor.useYn == '') {
                alert("모든 항목을 입력하여야 합니다.");
            } else {
                this.$.httpPost('/api/main/adm/ncs/insertNcsMinorList', this.insertMinor)
                .then(() => {
                    this.showMinor(this.midName, this.minorDetail.midClassCd);
                }).catch(this.$.httpErrorCommon);
                this.canMinorCol();
            }
        },

        showDetail(minorName, minorClassCd) {
            if( this.minorName == '') {
                this.minorName = minorName;
            }
            this.ncsDetail.ncsMinor = minorClassCd;
            this.detaDetail.minorClassCd = minorClassCd;
            this.insertDetail.minorClassCd = minorClassCd;

            var param = this.detaDetail;
            if(this.ncsDetail.useYn != '') {
                this.detaDetail.srch = this.ncsDetail.useYn;
            }
            this.$.httpPost("/api/main/adm/ncs/getNcsDetailList", param)
                .then(res => {
                // console.log("getNcsDetailList RESULT", res);
                this.ncsDetailList = res.data.ncsDetailList;
                this.$nextTick(() => {
                    this.$.focus(this.$refs.ncsDetail);
                });
            }).catch(this.$httpErrorCommon);
            

        },

        addDetailCol() {
            this.addDetailColum = '1';
        },

        canDetailCol() {
            this.addDetailColum = '';
            this.insertDetail.detailClassCd = '';
            this.insertDetail.detailClassNm = '';
            this.insertDetail.useYn = '';
            this.insertDetail.dutySpecYn = '';
        },

        changeDetail(detail, detailindex) {
            if(this.detailclick == '-1') {
                this.detailclick = detailindex;
                this.detaDetail.detailClassCd = detail.detailClassCd;
                this.detaDetail.detailClassNm = detail.detailClassNm;
                this.detaDetail.useYn = detail.useYn;
                this.detaDetail.dutySpecYn = detail.dutySpecYn;

            } else if (this.detailclick != '-1' && this.detailclick != detailindex) {
                this.detailclick = detailindex;
                this.detaDetail.detailClassCd = detail.detailClassCd;
                this.detaDetail.detailClassNm = detail.detailClassNm;
                this.detaDetail.useYn = detail.useYn;
                this.detaDetail.dutySpecYn = detail.dutySpecYn;
            } else {
                this.detailclick = '-1';
            }
        },

        updateDetailList() {
            // console.log('updateDetailList',this.detaDetail);

            this.$.httpPost('/api/main/adm/ncs/updateNcsDetailList', this.detaDetail)
                .then(() => {
                    this.showDetail(this.detaDetail.detailClassNm, this.detaDetail.minorClassCd);
                }).catch(this.$.httpErrorCommon);
            
            this.detailclick = '-1';
        },

        insertDetailList() {
            // console.log('InsertDetailList', this.insertDetail);

            if(this.insertDetail.detailClassCd == '' || this.insertDetail.detailClassNm == '' || this.insertDetail.useYn == '' || this.insertDetail.dutySpecYn == '') {
                alert("모든 항목을 입력하여야 합니다.");
            } else {
                this.$.httpPost('/api/main/adm/ncs/insertNcsDetailList', this.insertDetail)
                .then(() => {
                    this.showDetail(this.minorName, this.detaDetail.minorClassCd);
                }).catch(this.$.httpErrorCommon);
                this.canDetailCol();
            }
        },
        
        viewAbilDetail(detailClassCd, detailClassNm) {
            this.ncsDetail.ncsDeta = detailClassCd;
            this.detailName = detailClassNm;
            // console.log(this.ncsDetail);
            var params = {
                ncsMajorSeq : this.ncsDetail.ncsMajor,
                ncsMidSeq : this.ncsDetail.ncsMid,
                ncsMinorSeq : this.ncsDetail.ncsMinor,
                ncsDetaSeq : this.ncsDetail.ncsDeta,
                midName : this.midName,
                minorName : this.minorName,
                detailName : this.detailName,
                caller : {
                    name : this.$route.name,
                    params : this.ncsDetail
                }
            }

            // console.log(this.$router);
            // console.log('fsdfse', params);
            this.$router.push({name: 'MAN936M02', params});
        },


    },
    
}
</script>